class Navigation {
	constructor() {
		this.navigation = document.querySelector('.navigation');
		this.init();
	}

	init() {
		this.setupBindings();
	}

	setupBindings() {
		this.navigation.addEventListener('click', this.onNavigate.bind(this), false)

	}

	onNavigate(e) {
		if(e.target && e.target.nodeName === 'A') {
			this.navigation.querySelector('#navigation').checked = false;
		}
	}
}

new Navigation();