const list = document.querySelector('.video__list');

list.addEventListener('click', function (event) {
	const embed = document.querySelector('.video__embed');
	const videoMain = document.querySelector('.video--main');
	const videoframe = videoMain.querySelector('iframe');

	if (event.target.matches ? event.target.matches('.video__thumbnail') : event.target.msMatchesSelector('.video__thumbnail')) {

		videoMain.classList.remove('video--main');
		videoframe.setAttribute('src', videoframe.getAttribute('src').replace('?autoplay=1', ''));

		list.appendChild(videoMain);
		embed.appendChild(event.target);

		event.target.getElementsByTagName('iframe')[0].src += '?autoplay=1';
		event.target.classList.add('video--main');
	}
});
