class Wrapper {
	constructor() {
		this.wrappers = document.querySelectorAll("wrapper");
		this.starHeight = 94;
		this.resizeTimeout;

		this.init();
	}

	init() {
		this.setupBindings();
	}

	setupBindings() {
		const wrappers = this.wrappers,
					length = wrappers.length;

		let i = 0;

		for (; i < length; ++i) {
			const wrapper = wrappers[i],
						backgroundImageContainer = wrapper.querySelector(".wrapper--background-image"),
						isStar = wrapper.getAttribute("type").toString().toLowerCase() === "stars";

			if (isStar) {
				this.resizeHandler(backgroundImageContainer);

				window.addEventListener("resize", () => {
					this.resizeThrottler.call(this, backgroundImageContainer);
				}, false);
			}
		}
	}

	resizeThrottler(elementToResize) {
		// ignore resize events as long as an actualResizeHandler execution is in the queue
		if (!this.resizeTimeout) {
			this.resizeTimeout = setTimeout(() => {
				this.resizeTimeout = null;
				this.resizeHandler.call(this, elementToResize);
		
			// The actualResizeHandler will execute at a rate of 15fps
			}, 66);
		}
	}

	resizeHandler(elementToResize) {
		// Reset previous set height
		elementToResize.style.height = "";

		// Calculate and set new height
		const wrapperHeight = elementToResize.getBoundingClientRect().height,
					heightDifference = this.starHeight - (wrapperHeight % this.starHeight);

		elementToResize.style.height = (wrapperHeight + heightDifference) + "px";
	}
}

new Wrapper();