class Person {
	constructor() {
		this.people = document.querySelectorAll('.person');
		this.init();
	}

	init() {
		this.setupBindings();
	}

	setupBindings() {
		let i = 0;
		const length = this.people.length;

		for (; i < length; ++i) {
			const person = this.people[i],
						closeButton = person.querySelector(".person__popup-close");

			person.addEventListener('click', this.showPopup.bind(this, person), false);
			if (closeButton !== null) {
				closeButton.addEventListener('click', this.closePopup.bind(this, person), false)
			}
		}
	}

	showPopup(person) {
		const popup = person.querySelector(".person__popup");
		
		this.people.forEach((person) => {
			person.classList.remove('person__popup--shown');
		});

		if (popup !== null) {
			popup.classList.add("person__popup--shown");
		}
	}

	closePopup(person, e) {
		e.stopPropagation();
		
		const popup = person.querySelector(".person__popup");
		popup.classList.remove("person__popup--shown");
	}
}

new Person();
