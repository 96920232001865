function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

class StarBackground {
	constructor(element) {
		this.element = element;
		this.svg = window.d3.select(element.querySelector('svg'));

		this.starWidth = 90;
		this.starHeight = 90;

		this.create();
	}
	create() {
		var width = this.element.clientWidth;
		var height = this.element.clientHeight;

		this.svg.attr("width", width)
			.attr("height", height);

		const rows = Math.floor(width / this.starWidth);
		const cols = Math.floor(height / this.starHeight);

		for (let colStep = 0; colStep < rows; colStep++) {
			for (let rowStep = 0; rowStep < cols; rowStep++) {
				let mod = rowStep % 2 ? 75 : 0;
				let duration = getRandomInt(800, 4000);
				let delay = getRandomInt(300, 1000);

				this.svg.append("use")
					.attr('fill', '#292929')
					.attr('xlink:href', '#star-' + getRandomInt(1, 9))
					.attr('y', (130 * rowStep))
					.attr('x', (150 * colStep) + mod)
					.attr('height', 30)
					.attr('opacity', 0)
					.transition()
					.delay(delay)
					.duration(duration)
					.attr('opacity', 1);
			}
		}

	}
}

[].forEach.call(
	document.querySelectorAll('.star-wrap'),
	function (el) {
		new StarBackground(el);
	}
);
