const list = document.querySelector('.gallery__container');

//Setting class on the overlay
list.addEventListener('click', function (event) {
	var modal = document.querySelectorAll('.modal');
	if (event.target.matches ? event.target.matches('img') : event.target.msMatchesSelector('img')) {

		for (var i = 0; i < modal.length; i++) {
			modal[i].classList.add('is-active');
		}
	}
});

//Remove class from the over / Different click event
list.addEventListener('click', function (event) {
	var modal = document.querySelectorAll('.modal');
	if (event.target.matches ? event.target.matches('.close') : event.target.msMatchesSelector('.close')) {

		for (var i = 0; i < modal.length; i++) {
			modal[i].classList.remove('is-active');
		}
	}
});


