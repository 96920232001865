function getRandomInt(min, max) {
	min = Math.ceil(min);
	max = Math.floor(max);
	return Math.floor(Math.random() * (max - min)) + min; //The maximum is exclusive and the minimum is inclusive
}

class Paint {
	constructor() {
		this.svg = window.d3.select('#stripe-svg');

		this.stripeWidth = 140;
		this.stripeHeight = 725;
		this.stripePadding = 50;

		this.heightestScroll = 0;
		this.laststripeHeight = 0;

		this.stripes = [];

		this.create();
		this.update(0);
		
		window.addEventListener("resize", this.update.bind(this));

		var last_known_scroll_position = 0;
		var ticking = false;
		window.addEventListener('scroll', () => {
			last_known_scroll_position = window.scrollY;
			if (!ticking) {
				window.requestAnimationFrame(() => {
					this.update(last_known_scroll_position);
					ticking = false;
				});
			}
			ticking = true;
		});
	}

	create() {
		var width = document.getElementById('stripe-svg').clientWidth;
		var height = this.height = document.querySelector('.stripes').clientHeight;

		this.svg.attr('width', width)
			.attr('height', height);

		var linesToDraw = Math.ceil(width / this.stripeWidth);

		for(let step = 0; step < linesToDraw; step++) {
			let x = (this.stripeWidth + this.stripePadding) * step;

			const stripes = [1, 3, 5, 7, 9, 11];
			const currentStripe = stripes[getRandomInt(0,6)];
			let sg = this.svg.append('g')
				.attr('transform', `translate(${x} 20)`);

			let top = sg.append('use')
				.attr('fill', '#000')
				.attr('xlink:href', '#stripe-top-' + currentStripe)
				.attr('width', this.stripeWidth)
				.attr('x', -23);

			let bottom = sg.append('use')
				.attr('fill', '#000')
				.attr('xlink:href', '#stripe-bottom-' + currentStripe)
				.attr('width', this.stripeWidth)
				.attr('y', 60)
				.attr('x', -23)
				.attr('width', 100);
		
			let st = sg.append('rect')
				.attr('fill', 'url(#stripe-middle-' + currentStripe + ')')
				.attr('width', this.stripeWidth)
				.attr('transform', 'translate(-23 0)')
				.attr('x', 0)
				.attr('y', 63)
				.attr('height', 0);

			this.stripes.push({
				top: top,
				bottom: bottom,
				stripe: st
			});
		}
	}
	
	update(scroll) {
		if (scroll > this.heightestScroll) {
			this.heightestScroll = scroll;
		}

		this.stripes.forEach((g) => {

			const heightNoise = getRandomInt(0, 400);
			const delay = getRandomInt(0, 560);
			const duration = getRandomInt(50, 5500);
			const easement = window.d3.easeQuad;

			const clientHeight = document.documentElement.clientHeight;
			const topOffset = document.querySelector('.stripes').offsetTop;
			const topPartHeight = 55;
			const bottomPartHeight = 65;

			/*
				To figure out the stripe height we need to dot he following:

				1. Get the current scroll height, this will tell us the length. However we also need to subtract the top offset, as it's not always placed at the top.
				In this case is below the hero element.

				ScrollHeight - TopOffset

				2. However that is not all, we also need to subtract the top of the stripe as well as the bottom:
				
				ScrollHeight - ( TopOffset + TopPartHeight + BottomPartHeight )

				3. Now something is is still wrong, window.scrollY gives us 0 if we are at the top. We need to att the clientHeight.

				( ScrollHeight + ClientHeight ) - ( TopOffset + TopPartHeight + BottomPartHeight )

			*/

			// - (628+63+57)
			const maxStripeHeight = (this.heightestScroll + clientHeight) - (topOffset + topPartHeight +bottomPartHeight);
			let stripeHeight = maxStripeHeight - heightNoise;// (document.documentElement.clientHeight + this.heightestScroll) - maxStripeHeight - document.querySelector('.stripes').offsetTop;

			if(stripeHeight < 0) {
				stripeHeight = 0;
			}

			if(this.height < stripeHeight) {
				stripeHeight = this.height -  getRandomInt(150, 250);
			}

			// If the stripeHeight is not heigher than the last one, keep the last one.
			if(stripeHeight < g.currentHeight) {
				stripeHeight = g.currentHeight;
			}

			g.bottom.transition()
				.duration(duration)
				.delay(delay)
				.ease(easement)
				.attr('y', stripeHeight + 63);
		
			g.stripe.transition()
				.duration(duration)
				.delay(delay)
				.ease(easement)
				.attr('height', stripeHeight);


			g.currentHeight = stripeHeight;

			this.laststripeHeight = stripeHeight;
		
		});
	}
}

new Paint();
